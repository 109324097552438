import React from 'react'
import Layout from '../layouts'
import NavBar from '../components/common/NavBar'
import Legal from '../components/legal/Legal'
import { graphql } from 'gatsby'

const Index = ({ data }) => {
  const { phone, email, location } = data.info.contact;
  return (
    <Layout>
      <NavBar phoneNumber={phone} email={email} location={location} />
      <Legal />
    </Layout>
  )
}

export default Index;

export const query = graphql`
  query {
    info: dataJson {
      ...Contact
    }
  }
`;
